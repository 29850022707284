export var SearchList = [
  { labe: '采购单编号', code: 'buyOrderCode', type: 'input' },
  {
    labe: '相关合同',
    code: 'relationContract',
    type: 'input'
  },
  {
    labe: '公司名称',
    code: 'companyName',
    type: 'input'
  },
  {
    labe: '采购员',
    code: 'buyer',
    type: 'input'
  }
]
export var SearchData = {
  buyOrderCode: '',
  contractCode: '',
  companyName: '',
  buyer: ''
}

export var tableField = [
  { label: '采购订单号', code: 'orderCode', type: 'input', width: '' },
  { label: '相关合同', code: 'contractCode', type: 'input', width: '' },
  { label: '公司名称', code: 'companyName', type: 'input', width: '' },
  { label: '采购员', code: 'buyerName', type: 'input', width: '' },
  {
    label: '操作',
    code: 'act',
    width: '',
    type: 'action',
    actionData: [
      {
        actionLabel: '查看',
        operationButton: 'see'
      }
    ]
  }
]

export var tableData = []

<template>
<!-- 与我相关订单 -->
  <div class="list">
    <list-search :childData="childData" @onSearch="search"  @clearSearch="search"></list-search>
    <global-table :tableField="tableField" @tableAction="tableAction" :tableData="tableData" :paginationData="paginationData" @pageChange="pageChange"></global-table>
  </div>
</template>

<script>
import { request } from '@/assets/js/http.js'
import ListSearch from '@/components/actionList/ListSearch.vue'
import GlobalTable from '@/components/actionList/GlobalTable.vue'
import { SearchList, SearchData, tableField, tableData } from './js/myrelateOrder.js'
export default {
  components: { ListSearch, GlobalTable },
  name: 'RelateOrder',
  data () {
    var pageSize = parseInt((document.documentElement.clientHeight - 280) / 42)
    if (pageSize < 10) {
      pageSize = 10
    }
    return {
      tableField: tableField,
      tableData: tableData,
      searchdata: SearchData,
      paginationData: {},
      pageNum: 1,
      pageSize: pageSize,
      childData: { searchList: SearchList, searchData: SearchData }
    }
  },
  created () {
    this.RelateOrderList()
  },
  methods: {
    tableAction: function (index, data, clickBtn) {
      if (clickBtn.operationButton === 'see') {
        this.$router.push({ params: { viewName: 'ReorderDetail' }, query: { orderCode: data.orderCode, id: data.id } })
      }
    },
    RelateOrderList () {
      var pageSize = parseInt((document.documentElement.clientHeight - 280) / 42)
      if (pageSize < 10) {
        pageSize = 10
      }
      const obj = {
        pageNum: this.pageNum,
        pageSize: this.pageSize
      }
      request('/api/order/order/getOfMyOrderInfoByUserId', 'POST', obj).then((res) => {
        if (res.code === '200') {
          this.tableData = res.data.records
          this.paginationData = { pageSize: pageSize, total: res.data.total }
        }
      })
    },
    search: function (data) {
      data.pageNum = this.pageNum
      data.pageSize = this.pageSize
      this.searchdata = { ...data }
      request('/api/order/order/getOfMyOrderInfoByUserId', 'POST', this.searchdata).then((res) => {
        if (res.code === '200') {
          this.tableData = res.data.records
          this.paginationData = { pageSize: this.pageSize, total: res.data.total }
        }
      })
    },
    // 分页
    pageChange (data) {
      this.searchdata.pageNum = data
      this.searchdata.pageSize = this.pageSize
      request('/api/order/order/getOfMyOrderInfoByUserId', 'POST', this.searchdata).then((res) => {
        if (res.code === '200') {
          this.tableData = res.data.records
          this.paginationData = { pageSize: this.pageSize, total: res.data.total }
        }
      })
    }
  }
}
</script>
<style scoped lang="scss">
@import '@/assets/css/tablePadding.scss';
</style>
